<template>
    <div class="homeOrder">
        <div class="title">我的订单</div>


        <div class="tabss" v-loading="loading">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部订单" name="0">
                </el-tab-pane>
                <el-tab-pane label="待付款" name="1">
                </el-tab-pane>
                <el-tab-pane label="待发货" name="2">
                </el-tab-pane>
                <el-tab-pane label="待收货" name="3">
                </el-tab-pane>
                <el-tab-pane label="待评价" name="4">
                </el-tab-pane>
            </el-tabs>

            <div v-if="orderList.length == 0"
                style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                <img src="@/assets/wsj.png" style="width: 200px;object-fit: contain;" alt="">
                <div style="width: 100%;margin-top: 10px;">暂无数据</div>
            </div>
            <div class="son_box" v-for="(i, index) in orderList" :key="index" @click="detailsOfTheOrder(i)">
                <div class="dh">
                    <span>
                        <div>订单编号：{{ i.ono }}</div>
                        <div>创建时间：{{ i.createTime }}</div>
                    </span>
                    <i class="el-icon-delete" @click.stop="dele(i)"></i>
                </div>
                <div class="cont" v-for="(item, ind) in i.listOrderItem" :key="ind">
                    <div class="img_jiag">
                        <div class="img">
                            <img :src="item.img" alt="">
                        </div>
                        <div class="tetx">
                            <div class="name">{{ item.productName }}</div>
                            <div class="jiag" style="display: flex;align-items: center;">¥{{ item.paymentMoney || '面议' }}
                                <div v-if="item.type == '2'"
                                style="width: 40px;height: 20px;border-radius: 4px;text-align: center;line-height: 20px;font-size: 12px;color: #fff;background-color: #FF4242;margin-left: 5px;">
                                定金</div></div>
                            
                        </div>
                    </div>
                    <div class="typee">
                        <div class="ty">购买数量</div>
                        <div class="num">数量*{{ item.pnum }}</div>
                    </div>
                    <div class="typee2">
                        <div class="ty">收货人</div>
                        <div class="nam">{{ i.uname }}</div>
                    </div>
                    <div class="typee">
                        <div class="ty2">支付方式</div>
                        <div class="num" v-if="i.payType == 'WX'">微信支付</div>
                        <div class="num" v-else-if="i.payType == 'HK'">线下汇款</div>
                        <div class="num" v-else>支付宝支付</div>
                    </div>
                    <div class="typee2">
                        <div class="ty">订单状态</div>
                        <div class="nam"
                            v-if="(i.ostatus == 'DZF' || i.ostatus == 'DZF1') && i.otype == 'DISCUSS' && i.paymentMoney == '0'">
                            等待商家报价</div>
                        <div class="nam"
                            v-if="(((i.ostatus == 'DZF') && i.otype != 'DISCUSS') || ((i.ostatus == 'DZF') && i.otype == 'DISCUSS' && i.paymentMoney != '0'))">
                            等待买家付款</div>
                        <div class="nam" v-if="i.ostatus == 'DFH'">等待卖家发货</div>
                        <div class="nam" v-if="(i.ostatus == 'DZF1' || i.ostatus == 'DZF2') && i.payType == 'HK'">线下付款审核中
                        </div>
                        <div class="nam" style="color: #FF4242;" v-if="i.ostatus == 'DZF' && i.payType == 'HK' && i.platformAuditRemark">线下汇款未通过
                        </div>
                        <div class="nam" v-if="i.ostatus == 'DSH'">卖家已发货</div>
                        <div class="nam" v-if="i.ostatus == 'DPJ'">交易成功</div>
                        <div class="nam" v-if="i.ostatus == 'THSQ'">售后审核中</div>
                        <div class="nam" v-if="i.ostatus == 'JYGB'">交易关闭</div>
                        <div class="nam" v-if="i.ostatus == 'JYCG'">订单完成</div>

                    </div>
                    <div class="btn_box"
                        :style="{ borderBottom: ind >= 0 && i.listOrderItem.length > 1 && ind != (i.listOrderItem.length - 1) ? ' 1px solid #fff' : '' }">
                        <div v-if="ind == 0">
                            <!-- <div class="btn">再次购买</div>
                            <div class="btn2" @click.stop="toEvaluate">待评价</div> ===todo=== -->
                            <div class="btn2" v-if="i.ostatus === 'DZF'" @click.stop="cancellationOrderClick(i)">取消订单</div>
                            <div class="btn" @click.stop="paymentClick(i)"
                                v-if="(i.ostatus === 'DZF' && i.paymentMoney && i.otype == 'DISCUSS') || (i.ostatus === 'DZF' && i.otype != 'DISCUSS' && !i.platformAuditRemark)">
                                付款</div>
                            <div class="btn" style="width: 100px;" @click.stop="getRepayment(i)"
                                v-if="(i.ostatus === 'DZF' && i.paymentMoney && i.otype == 'DISCUSS') || (i.ostatus === 'DZF' && i.otype != 'DISCUSS' && i.platformAuditRemark)">
                                查看并重新付款</div>
                            <div class="btn"
                                v-if="i.ostatus === 'DFH' || ((i.ostatus === 'DZF1' || i.ostatus === 'DZF2') && i.payType == 'HK')"
                                @click.stop="selectAssClick(i)">修改地址</div>
                            <div class="btn2" v-if="i.ostatus === 'DSH'" @click.stop="shouHuoClick(i)">确认收货</div>
                            <div class="btn" v-if="i.ostatus === 'DPJ'" @click.stop="toEvaluate(i)">待评价</div>
                            <div class="btn" v-if="i.ostatus === 'DSH'" @click.stop="viewLogisticsClick(i)">查看物流</div>
                            <div class="btn" v-if="i.ostatus === 'JYCG' || i.ostatus === 'JYGB'"
                                @click.stop="gouMaiClick(item.spuId)">再次购买</div>

                            <template v-if="!i.ostatus.includes('DZF') && !i.ostatus.includes('JYGB')">
                                <div class="btn2" @click.stop="Invoicing(i)" v-if="!i.invoiceId">
                                    申请开票</div>

                                <div class="btn2" @click.stop="InvoicingImg(i)" v-if="i.invoiceId && i.userInvoiceProof">
                                    查看发票</div>

                                <div class="btn3" v-if="i.invoiceId && !i.userInvoiceProof">
                                    开票中</div>
                            </template>

                        </div>
                    </div>

                </div>
            </div>

            <!-- 查看发票对话框 -->
            <el-dialog :visible.sync="userInvoiceProofShow">
                <img width="100%" :src="userInvoiceProof" alt="">
            </el-dialog>

            <!-- 查看物流对话框 -->
            <el-dialog title="物流信息" :visible.sync="centerDialogVisible" width="50%">
                <div style="width: 100%;display: flex;align-items: center;">
                    <div style="color: #333;">{{ viewLogistics.shipName }}</div>
                    <div style="color: #666;margin: 0 20px;">{{ viewLogistics.shipNo }}</div>
                    <div class="cur" @click="copyText(viewLogistics.shipNo)" style="color: red;">点击复制</div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="centerDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>


            <!-- 分页 -->
            <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :currentPage="paging.page"
                :page-size="paging.size"></paging>

            <!-- 上传凭证对话框 -->
            <!-- <offlineHK :accountInfo="accountInfo" :HKshow="HKshow" :oid="oid" @HKshowCl="HKshowCl" /> -->


            <!-- 支付对话框 -->
            <offlineZF :centerDialogVisible2.sync="centerDialogVisible2" :paymentObj="paymentObj"
                @centerDialogVisible2Click="centerDialogVisible2Click"></offlineZF>

            <!-- 线下汇款拒绝对话框 -->
            <offlineHKJuJ :dialogVisible="dialogVisibleJuJ" @dialogVisibleShowClick="dialogVisibleShowClick"
                @repaymentClick="repaymentClick" :platformAuditRemark="paymentObj.platformAuditRemark" />

            <!-- 申请开票对话框 -->
            <InvoicingKP :outerVisible="outerVisible" @outerVisibleShowClick="outerVisibleShowClick"
                @getOrderList="getOrderList" :paymentObj="paymentObj" />

        </div>

    </div>
</template>

<script>
import offlineZF from "@/pages/myPage/offlineZF.vue";
import offlineHK from "@/pages/myPage/offlineHK.vue";
import offlineHKJuJ from "@/pages/myPage/offlineHKJuJ.vue";
import InvoicingKP from "@/pages/myPage/InvoicingKP.vue";
import Clipboard from 'clipboard';
import { getMyOrderList, getOrderSelectAddreee, getViewLogisticsClick, postSourcingPayment, getOrderIdInfoDel, getStoreAccount } from "@/utils/api/myApi/index"
import paging from "@/components/paging.vue";
export default {
    name: 'myOrder',
    components: {
        paging,
        offlineHK,
        offlineZF,
        offlineHKJuJ,
        InvoicingKP
    },
    data() {
        return {
            // 开票
            outerVisible: false,
            // 查看开票对话框
            userInvoiceProofShow: false,
            // 发票图片
            userInvoiceProof: '',

            HKshow: false, // 上传凭证对话框
            accountInfo: {}, // 汇款信息
            oid: '',
            outInShow: false,//汇款信息
            centerDialogVisible: false,
            centerDialogVisible2: false,
            dialogVisibleJuJ: false,
            loading: true,
            activeName: 'second',
            handleType: 0,
            paging: {
                size: 5,
                page: 1,
                total: 0,
                oStatus: '',
            },
            // 订单列表
            orderList: [],
            // 物流信息
            viewLogistics: {},
            // 支付方式
            paymentArr: [
                {
                    name: '微信支付',
                    src: require('@/assets/icon/wxzf.png'),
                    act: true
                },
                {
                    name: '支付宝支付',
                    src: require('@/assets/icon/zfbzf.png'),
                    act: false
                },
                {
                    name: '线下汇款',
                    src: require('@/assets/icon/xxhk.png'),
                    act: false
                },
            ],
            payType: 'WX',
            // 支付信息
            paymentObj: {}
        }
    },
    mounted() {
        this.activeName = this.$route.query.activeName || '0'
        this.handleClick(this.activeName)
        this.getOrderList()
        this.getStoreAccountById()
    },
    methods: {
        dialogVisibleShowClick() {
            this.dialogVisibleJuJ = false
        },
        repaymentClick() {
            this.dialogVisibleJuJ = false
            this.centerDialogVisible2 = true
        },
        getRepayment(i) {
            console.log(i)
            this.paymentObj = i
            this.dialogVisibleJuJ = true
        },
        // -
        centerDialogVisible2Click() {
            console.log("我的页面组件")
            this.centerDialogVisible2 = false
            this.getOrderList()
        },
        // 获取汇款账户信息
        async getStoreAccountById() {
            const res = await getStoreAccount()
            if (res.statusCode == 8201) {
                this.accountInfo = res.data
            }
        },
        HKshowCl() {
            this.HKshow = false
        },
        handleClick(tab, event) {
            this.loading = true
            this.handleType = tab.index || tab
            if (this.handleType == 0) {
                this.paging.oStatus = ''
            }
            if (this.handleType == 1) {
                this.paging.oStatus = 'DZF'
            }
            if (this.handleType == 2) {
                this.paging.oStatus = 'DFH'
            }
            if (this.handleType == 3) {
                this.paging.oStatus = 'DSH'
            }
            if (this.handleType == 4) {
                this.paging.oStatus = 'DPJ'
            }
            this.paging.page = 1
            this.getOrderList()
            console.log(tab.index);
        },
        // 点击跳转订单详情
        detailsOfTheOrder(i) {
            this.$router.push(`/myIndex/orderDetails?oid=${i.oid}&payType=${i.payType}`)
        },
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.paging.page = val
            this.getOrderList()
        },
        // 删除订单
        dele(i) {
            this.$confirm('此操作将永久删除该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await getOrderIdInfoDel(i.oid)
                if (res.data) {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getOrderList()
                } else {
                    this.$message({
                        type: 'error',
                        message: '操作失败!'
                    });
                }

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        // 待评价
        toEvaluate(i) {
            console.log(i)
            this.$router.push(`/myIndex/toEvaluate?oid=${i.oid}&payType=${i.payType}`)
        },
        // 再次购买
        gouMaiClick(spuId) {
            window.open(`#/commodity/productDetails?ids=${spuId}`, '_blank');
        },
        // 修改地址
        selectAssClick(i) {
            console.log(i.oid)
            this.$router.push(`/myIndex/myAddress?actMyNum=4&type=select&oid=${i.oid}`)
        },
        // 确认收货
        async shouHuoClick(i) {
            console.log(i)
            let data = {
                oId: i.oid,
                oStatus: 'DPJ'
            }
            const res = await getOrderSelectAddreee(data)
            if (res.data) {
                this.$message.success('操作成功')
                this.getOrderList()
            } else {
                this.$message.error('操作失败')
            }
        },
        // 查看物流
        async viewLogisticsClick(i) {
            const res = await getViewLogisticsClick(i.oid)
            if (res.statusCode == 8201) {
                this.viewLogistics = res.data
                this.centerDialogVisible = true
            } else {
                this.$message.error('获取物流信息失败')
            }

        },
        // 申请开票
        Invoicing(i) {
            this.paymentObj = i
            this.outerVisible = true
        },
        // 查看发票
        InvoicingImg(i) {
            this.userInvoiceProof = i.userInvoiceProof
            this.userInvoiceProofShow = true
        },
        // 关闭开票对话框
        outerVisibleShowClick() {
            this.outerVisible = false
        },
        // 复制快递单号
        copyText(shipNo) {
            const textToCopy = shipNo;
            const clipboard = new Clipboard('.el-button', {
                text: () => textToCopy
            });
            let that = this
            clipboard.on('success', () => {
                // 复制成功后的处理逻辑
                that.$message.success('复制成功')
                // that.centerDialogVisible = false
                // 解绑事件
                clipboard.destroy();
            });
            clipboard.on('error', () => {
                // 复制失败后的处理逻辑
                that.$message.error('复制失败')
                // 解绑事件
                clipboard.destroy();
            });
            // 手动触发复制
            clipboard.onClick(event);
        },
        // 取消订单
        async cancellationOrderClick(i) {
            let data = {
                oId: i.oid,
                oStatus: 'JYGB',
                // payType: 
            }
            const res = await getOrderSelectAddreee(data)
            if (res.data) {
                this.$message.success('已取消该订单')
                this.getOrderList()
            } else {
                this.$message.success('操作失败')
            }
        },
        // 付款 
        paymentClick(i) {
            console.log(i)
            this.paymentObj = i
            this.centerDialogVisible2 = true
        },
        paymentType(index) {
            this.paymentArr.map(t => t.act = false)
            this.paymentArr[index].act = true
            if (index == 1) {
                this.payType = 'ZFB'
                this.outInShow = false
            } else if (index == 0) {
                this.payType = 'WX'
                this.outInShow = false
            } else if (index == 2) {
                this.outInShow = true
                // 线下汇款
                this.payType = 'HK'
            }
            console.log(this.payType)
        },
        // 付款 H5
        async liJiZF() {
            if (this.payType == 'HK') {
                this.centerDialogVisible2 = false
                this.oid = this.paymentObj.oid
                console.log(this.oid)
                this.HKshow = true
                return
            }
            var oids = []
            oids.push(this.paymentObj.oid)
            if (this.payType == 'WX') {
                var parme = {
                    type: 'mall',
                    payType: 'WX',
                    tradeType: 'NATIVE',
                    oId: JSON.stringify(oids),
                }
            } else {
                var parme = {
                    type: 'mall',
                    payType: 'AliPay',
                    oId: JSON.stringify(oids),
                    returnUrl: this.$store.state.returnUrl // 内网无法访问，这里是支付完成后，跳转测试
                }
            }
            const res = await postSourcingPayment(parme)
            if (res.statusCode == 8201) {
                if (this.payType == 'WX') {
                    let couponNum = this.paymentObj.paymentMoney
                    this.$router.push(`/payment/WeChatPayPage?codeUrl=${res.data.codeUrl}&outTradeNo=${res.data.outTradeNo}&couponNum=${couponNum}`)
                } else {
                    var tempwindow = window.open('_blank')
                    tempwindow.location = res.data
                }
            }
        },
        // 获取订单列表
        async getOrderList() {
            const res = await getMyOrderList({
                current: this.paging.page,
                size: this.paging.size,
                oStatus: this.paging.oStatus
            })
            if (res.statusCode == 8201) {
                this.loading = false
                this.orderList = res.data.records
                this.paging.total = res.data.total
            }
        }
    }
}
</script>

<style lang="less" scoped>
.xxhk {
    width: 380px;
    height: 137px;
    padding: 12px 20px 12px 40px;
    box-sizing: border-box;
    background: url("../../assets/xxhkBg.png") no-repeat;
    background-size: 100% 100%;

    >.title {
        font-size: 14px;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #333333;
        margin-bottom: 10px;
    }

    >.son {
        width: 100%;
        display: flex;
        align-items: center;
        padding-right: 20px;
        box-sizing: border-box;
        margin-bottom: 7px;

        >.l {
            width: 63px;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
        }

        >.z {
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-right: 15px;
        }

        >.r {
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
        }
    }
}

.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    >.tabss {
        width: 100%;
        height: auto;
        padding: 30px 20px;
        box-sizing: border-box;
        background-color: #fff;
        margin-top: 20px;

        .son_box {
            width: calc(100% - 2px);
            height: auto;
            border: 1px solid #E0E0E0;
            margin-bottom: 20px;


            >.dh {
                width: 100%;
                padding: 11px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                color: #333;
                background-color: #EEEEEE;

                >span {
                    display: flex;

                    >div {
                        margin-right: 40px;
                    }
                }

                >i {
                    cursor: pointer;
                }

            }

            >.cont {
                width: 100%;
                min-height: 88px;
                display: flex;
                align-items: center;
                border-top: 1px solid #E0E0E0;
                padding-top: 4px;

                >.img_jiag {
                    width: 370px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 15px;
                    box-sizing: border-box;
                    border-right: 1px solid #E0E0E0;

                    >.img {
                        width: 64px;
                        height: 64px;

                        >img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    >.tetx {
                        margin-left: 10px;

                        >.name {
                            font-size: 14px;
                            color: #333;
                        }

                        >.jiag {
                            font-size: 14px;
                            color: #FF4242;
                            margin-top: 10px;
                        }
                    }
                }

                >.typee {
                    width: 90px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    >.ty {
                        font-size: 12px;
                        color: #FF4242;
                    }

                    >.ty2 {
                        font-size: 12px;
                        color: #333;
                    }

                    >.num {
                        font-size: 12px;
                        color: #999999;
                        margin-top: 5px;
                    }
                }

                >.typee2 {
                    width: 160px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-left: 1px solid #E0E0E0;
                    border-right: 1px solid #E0E0E0;

                    >.ty {
                        font-size: 12px;
                        color: #333333;
                    }

                    >.nam {
                        font-size: 12px;
                        color: #999999;
                        margin-top: 5px;
                    }
                }

                >.btn_box {
                    width: 130px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    >div {


                        >div {
                            width: 80px;
                            height: 30px;
                            border-radius: 4px;
                            box-sizing: border-box;
                            font-size: 12px;
                            text-align: center;
                            line-height: 30px;
                            margin-bottom: 5px;
                            cursor: pointer;
                        }

                        .btn {
                            background-color: #FF4242;
                            color: #fff;
                        }

                        .btn2 {
                            color: #FF4242;
                            border: 1px solid #FF4242;
                        }

                        .btn3 {
                            color: #666;
                        }
                    }

                }
            }
        }
    }
}

.paging {
    width: 100%;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment {
    width: 100%;
    height: 115px;
    padding: 14px 28px;
    box-sizing: border-box;
    border: 1px solid #E0E0E0;
    margin-top: 20px;

    >.title {
        width: 100%;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    .zf {
        width: 120px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #E0E0E0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        margin-top: 17px;
        cursor: pointer;
        color: #000;

        >.img {
            width: 20px;
            height: 20px;
            margin-right: 8px;

            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        >span {
            font-size: 14px;
        }
    }
}

.actShow {
    border: 1px solid #FF4242 !important;
    color: #FF4242 !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}


/deep/ .el-tabs__nav-wrap::after {
    background-color: #fff;
}

/deep/ .el-tabs__item.is-active {
    color: #FF4242;
}

/deep/ .el-tabs__active-bar {
    background-color: #FF4242;
}

/deep/ .el-tabs__item:hover {
    color: #FF4242;
}


// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>